<template>
    <div>
        <!-- info -->
        <b-row>
            <b-col>
                <accessories-detail :info="info" @delete="deleteAcc"/>
            </b-col>
        </b-row>
        <!-- Stock -->
        <b-row>
            <b-col>
                <b-card>
                    <div class="d-flex justify-content-between">
                        <div><h3>Stock</h3></div>
                        <div>
                            <b-button class="ml-1" size="sm" variant="relief-success" v-b-modal.add-sub-modal>เพิ่มอุปกรณ์</b-button>
                        </div>
                    </div>
                    <b-table responsive="md" :items="sub_accessories" :fields="fields" >
                        <template #cell(action)="data">
                            <confirm-delete-button @delete="deleteSub" :data="data.item._id" />
                        </template>
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
        <edit-modal />
        <add-sub-modal />
    </div>
</template>

<script>
import { BTable,BCard, BCardText ,BRow, BCol , BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import EditModal from './component/EditModal.vue'
import AddSubModal from './component/AddSubModal.vue'
import AccessoriesDetail from './component/AccessoriesDetail.vue'
import ConfirmDeleteButton from '../components/ConfirmDeleteButton.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
    components: {
        BCard,
        BCardText,
        BRow, 
        BCol,
        BTable,
        BButton,
        BListGroup,
        BListGroupItem,
        EditModal,
        AddSubModal,
        ConfirmDeleteButton,
        AccessoriesDetail
    },
    mounted() {
        this.$root.$on('accessories-reload',async () => {
            await this.loadData()
        })
    },
    data() {
        return {
            selected:null,
            acc_id : this.$route.params.id,
            info: {},
            sub_accessories:[],
            fields: ['serial','qr_code','purchase_price','status','note','action']
        }
    },
    created() {
        this.loadData();;
    },
    methods:{
        loadData(){
            this.$http({
                method: 'GET',
                url: `/accessories/`+this.$route.params.id
            }).then(x => {
                this.info = x.data.data.info
                this.sub_accessories = x.data.data.sub_accessories
            })
        },
        deleteSub(x){
            if(x.confirm){
                this.$http({
                    method: 'DELETE',
                    url: `/accessories/sub/${x.data}`
                }).then(x => {
                    this.loadData()
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'ลบอุปกรณ์',
                            icon:'CheckIcon',
                            text: 'ลบอุปกรณ์สำเร็จแล้ว',
                            variant:'success',
                        },
                    })
                })
            }
        },
        deleteAcc(){
            
        }

    }
}
</script>

<style>
</style>
