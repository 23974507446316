<template>
    <div>
        <b-card>
            <div class="d-flex justify-content-between">
                <div><h3>{{ info.name }}</h3></div>
                <div class="d-flex justify-content-between">
                    <b-button size="sm" variant="relief-warning" v-b-modal.edit-modal>Edit</b-button>
                    <confirm-delete-button @delete="deleteAcc" :data="info._id" />
                </div>
            </div>
            <br>
            <b-row>
                <b-col>
                    <b-list-group flush>
                        <b-list-group-item>
                            <div class="d-flex justify-content-between">
                                <b>ID อุปกรณ์</b>
                                {{ info._id }}
                            </div>
                        </b-list-group-item>
                        <b-list-group-item>
                            <div class="d-flex justify-content-between">
                                <b>ชื่ออุปกรณ์</b>
                                {{ info.name }}
                            </div>
                        </b-list-group-item>
                        <b-list-group-item>
                            <div class="d-flex justify-content-between">
                                <b>ชื่อในสัญญา</b>
                                {{ info.lease_name }}
                            </div>
                        </b-list-group-item>
                        <b-list-group-item>
                            <div class="d-flex justify-content-between">
                                <b>ราคาในสัญญา</b>
                                {{ info.lease_price }} บาท
                            </div>
                        </b-list-group-item>
                    </b-list-group>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import { BCard,BRow, BCol , BButton ,BTable, BListGroup,BListGroupItem} from 'bootstrap-vue'
import ConfirmDeleteButton from '../../components/ConfirmDeleteButton.vue'

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BButton,
        BTable,
        ConfirmDeleteButton,
        BListGroup,
        BListGroupItem
    },
    props:['info'],
    methods: {
        deleteAcc(x){
            if(x.confirm){
                this.$http({
                    method: 'DELETE',
                    url: `/accessories/${x.data}`
                }).then(x => {
                    this.$router.push({path:'/accessories/list'})
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'ลบอุปกรณ์',
                            icon:'CheckIcon',
                            text: 'ลบอุปกรณ์สำเร็จแล้ว',
                            variant:'success',
                        },
                    })
                })
            }
        }
    },
}
</script>