<template>
  <div>
    <b-modal
      id="edit-modal"
      ref="edit-modal"
      title="แก้ไขอุปกรณ์"
      no-close-on-backdrop
      size="md"
      hide-footer
    >
      <validation-observer ref="editAccessories">
        <b-form @submit.prevent="editAccessories">
          <!-- ชื่อเสริม -->
          <b-form-group label="ชื่อเสริม">
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-input
                v-model="form.name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ชื่อในสัญญา -->
          <b-form-group label="ชื่อในสัญญา">
            <validation-provider
              #default="{ errors }"
              name="Lease Name"
              rules="required"
            >
              <b-form-input
                v-model="form.lease_name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ราคาในสัญญา -->
          <b-form-group label="ราคาในสัญญา">
            <validation-provider
              #default="{ errors }"
              name="Lease Price"
              rules="required"
            >
              <b-form-input
                v-model="form.lease_price"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Is GoPro -->
          <b-form-group label="แสดงในสัญญา">
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.active"
                name="some-radios5"
                :value="true"
              >
                แสดง
              </b-form-radio>
              <b-form-radio
                v-model="form.active"
                name="some-radios5"
                :value="false"
              >
                ซ่อน
              </b-form-radio>
            </div>
          </b-form-group>

          <hr />
          <div class="text-right justify">
            <b-button variant="relief-secondary" class="mr-1" @click="cancel">
              Cancel
            </b-button>
            <b-button
              v-if="!loading"
              variant="relief-success"
              @click="validationForm"
            >
              แก้ไข
            </b-button>
            <b-button
              variant="relief-success"
              disabled
              class="mr-1"
              v-if="loading"
            >
              <b-spinner small />
              Loading...
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import {
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
  BButton,
  BFormRadio,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
import _ from "lodash";
export default {
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormRadio,
  },
  data() {
    return {
      form: {
        name: null,
        lease_name: null,
        active: false,
      },
      required,
      loading: false,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    cancel() {
      this.$refs["edit-modal"].hide();
    },
    validationForm() {
      this.$refs.editAccessories.validate().then((success) => {
        console.log("validationForm");
        this.$http({
          method: "PUT",
          url: "/accessories/" + this.$route.params.id,
          data: this.form,
        }).then((x) => {
          this.$root.$emit("accessories-reload");
          this.$refs["edit-modal"].hide();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "แก้ไขอุปกรณ์",
              icon: "CheckIcon",
              text: "แก้ไขข้อมูลอุปกรณ์แล้ว",
              variant: "success",
            },
          });
        });
      });
    },
    loadData() {
      this.$http({
        method: "GET",
        url: `/accessories/` + this.$route.params.id,
      }).then((x) => {
        this.form = _.get(x, "data.data.info", {});
      });
    },
  },
};
</script>
