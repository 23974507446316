var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"add-sub-modal",attrs:{"id":"add-sub-modal","title":"เพิ่มอุปกรณ์","no-close-on-backdrop":"","size":"md","hide-footer":""}},[_c('validation-observer',{ref:"addSubAccessories"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.addSubAccessories($event)}}},[_c('b-form-group',{attrs:{"label":"Serial"}},[_c('validation-provider',{attrs:{"name":"Serial"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form.serial),callback:function ($$v) {_vm.$set(_vm.form, "serial", $$v)},expression:"form.serial"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"QR Code"}},[_c('validation-provider',{attrs:{"name":"QR Code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form.qr_code),callback:function ($$v) {_vm.$set(_vm.form, "qr_code", $$v)},expression:"form.qr_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"ราคา"}},[_c('validation-provider',{attrs:{"name":"Price"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form.purchase_price),callback:function ($$v) {_vm.$set(_vm.form, "purchase_price", $$v)},expression:"form.purchase_price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"note"}},[_c('validation-provider',{attrs:{"name":"Note"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form.note),callback:function ($$v) {_vm.$set(_vm.form, "note", $$v)},expression:"form.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"สถานะ"}},[_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{attrs:{"name":"some-radios5","value":"enable"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},[_vm._v(" เปิดใช้งาน ")]),_c('b-form-radio',{attrs:{"name":"some-radios5","value":"wait_return"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},[_vm._v(" รอลูกค้าคืน ")]),_c('b-form-radio',{attrs:{"name":"some-radios5","value":"wait_buy"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},[_vm._v(" หายยังไม่ได้ซื้อ ")]),_c('b-form-radio',{attrs:{"name":"some-radios5","value":"cancel"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},[_vm._v(" ยกเลิก ")])],1)]),_c('hr'),_c('div',{staticClass:"text-right justify"},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"relief-secondary"},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),(!_vm.loading)?_c('b-button',{attrs:{"variant":"relief-success"},on:{"click":_vm.validationForm}},[_vm._v(" เพิ่มอุปกรณ์ ")]):_vm._e(),(_vm.loading)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"relief-success","disabled":""}},[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Loading... ")],1):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }