<template>
  <div>
    <b-modal
      id="add-sub-modal"
      ref="add-sub-modal"
      title="เพิ่มอุปกรณ์"
      no-close-on-backdrop
      size="md"
      hide-footer
    >
      <validation-observer ref="addSubAccessories">
        <b-form @submit.prevent="addSubAccessories">
          <!-- Serail -->
          <b-form-group label="Serial">
            <validation-provider #default="{ errors }" name="Serial">
              <b-form-input
                v-model="form.serial"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- QR Code -->
          <b-form-group label="QR Code">
            <validation-provider #default="{ errors }" name="QR Code">
              <b-form-input
                v-model="form.qr_code"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ราคา -->
          <b-form-group label="ราคา">
            <validation-provider #default="{ errors }" name="Price">
              <b-form-input
                v-model="form.purchase_price"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- note -->
          <b-form-group label="note">
            <validation-provider #default="{ errors }" name="Note">
              <b-form-input
                v-model="form.note"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Is GoPro -->
          <b-form-group label="สถานะ">
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.status"
                name="some-radios5"
                value="enable"
              >
                เปิดใช้งาน
              </b-form-radio>
              <b-form-radio
                v-model="form.status"
                name="some-radios5"
                value="wait_return"
              >
                รอลูกค้าคืน
              </b-form-radio>
              <b-form-radio
                v-model="form.status"
                name="some-radios5"
                value="wait_buy"
              >
                หายยังไม่ได้ซื้อ
              </b-form-radio>
              <b-form-radio
                v-model="form.status"
                name="some-radios5"
                value="cancel"
              >
                ยกเลิก
              </b-form-radio>
            </div>
          </b-form-group>

          <hr />
          <div class="text-right justify">
            <b-button variant="relief-secondary" class="mr-1" @click="cancel">
              Cancel
            </b-button>
            <b-button
              v-if="!loading"
              variant="relief-success"
              @click="validationForm"
            >
              เพิ่มอุปกรณ์
            </b-button>
            <b-button
              variant="relief-success"
              disabled
              class="mr-1"
              v-if="loading"
            >
              <b-spinner small />
              Loading...
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
  BButton,
  BFormRadio,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BFormRadio,
  },
  data() {
    return {
      form: {
        status: "enable",
      },
      required,
      loading: false,
    };
  },
  created() {},
  methods: {
    validationForm() {
      this.$refs.addSubAccessories.validate().then((success) => {
        this.form.accessories_id = this.$route.params.id;
        this.$http({
          method: "POST",
          url: "/accessories/sub",
          data: this.form,
        })
          .then((x) => {
            this.$refs["add-sub-modal"].hide();
            this.form = {
              status: "enable",
            };
            this.$root.$emit("accessories-reload");

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "เพิ่มอุปกรณ์",
                icon: "CheckIcon",
                text: "เพิ่มอุปกรณ์สำเร็จ",
                variant: "success",
              },
            });
          })
          .catch((e) => {
            console.log("error", e);
          });
      });
    },
    cancel() {
      this.$refs["add-sub-modal"].hide();
    },
  },
};
</script>
